<template>
    <HeadlessTransitionRoot as="template" :show="mobileMenuOpen">
        <HeadlessDialog class="relative z-50 lg:hidden" @close="mobileMenuOpen = false">
            <HeadlessTransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/80" />
            </HeadlessTransitionChild>
            <div class="fixed inset-0 flex">
                <HeadlessTransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                    <HeadlessDialogPanel class="relative flex w-full max-w-xs flex-1">
                        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-[#202226] px-6 py-5">
                            <div class="flex items-center justify-between">
                                <div class="flex py-2 shrink-0 items-center">
                                    <NuxtLink to="/">
                                        <NuxtImg class="h-5 w-auto mx-auto block dark:hidden" src="/logo-dark.png" />
                                        <NuxtImg class="h-5 w-auto mx-auto hidden dark:block" src="/logo.png" />
                                    </NuxtLink>
                                </div>
                                <button @click="mobileMenuOpen = false">
                                    <LucideX class="size-5 dark:text-white" />
                                </button>
                            </div>
                            <nav id="sidebarNav" class="flex flex-1 flex-col -mt-1">
                                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                                    <li>
                                        <ul role="list" class="space-y-2.5">
                                            <li>
                                                <NuxtLink to="/" class="link">Home</NuxtLink>
                                            </li>
                                            <li>
                                                <HeadlessDisclosure as="div" v-slot="{ open }" :default-open="['/da-hood', '/bladeball', '/mm2', '/gpo', '/ps-99', '/adopt-me'].some(path => $route.path === path || $route.path === `${path}/`)">
                                                    <HeadlessDisclosureButton class="text-gray-900 dark:text-white hover:text-primary flex w-full justify-between items-center gap-x-3 rounded-md text-left text-sm/6 font-semibold">
                                                        Games
                                                        <ChevronRightIcon class="size-5 shrink-0" :class="{ 'rotate-90': open }" aria-hidden="true" />
                                                    </HeadlessDisclosureButton>
                                                    <HeadlessDisclosurePanel as="ul" class="my-3 space-y-3 pl-3">
                                                        <li>
                                                            <NuxtLink to="/da-hood" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/da-hood.png" class="h-6 rounded-md" />
                                                                    <span>Da Hood</span>
                                                                    <VerifiedBadge class="h-[22px]" />
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/bladeball" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/bladeball.png" class="h-6 rounded-md" />
                                                                    <span>Blade Ball</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/mm2" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/mm2.svg" class="h-6 rounded-md" />
                                                                    <span>Murder Mystery 2</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/gpo" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/gpo.png" class="h-6 rounded-md" />
                                                                    <span>Grand Piece Online</span>
                                                                    <VerifiedBadge class="h-[22px]" />
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/ps-99" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/ps99.png" class="h-6 rounded-md" />
                                                                    <span>Pet Simulator 99</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <!--
                                                        <li>
                                                            <NuxtLink to="/adopt-me" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/adopt-me.png" class="h-6 rounded-md" />
                                                                    <span>Adopt Me</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        -->
                                                    </HeadlessDisclosurePanel>
                                                </HeadlessDisclosure>
                                                </li>
                                                <li>
                                                <HeadlessDisclosure as="div" v-slot="{ open }" :default-open="['/da-hood/ads', '/bladeball/ads', '/mm2/ads', '/gpo/ads', '/ps-99/ads', '/adopt-me/ads'].some(path => $route.path === path || $route.path === `${path}/`)">
                                                    <HeadlessDisclosureButton class="text-gray-900 dark:text-white hover:text-primary flex w-full justify-between items-center gap-x-3 rounded-md text-left text-sm/6 font-semibold">
                                                        Trade Ads
                                                        <ChevronRightIcon class="size-5 shrink-0" :class="{ 'rotate-90': open }" aria-hidden="true" />
                                                    </HeadlessDisclosureButton>
                                                    <HeadlessDisclosurePanel as="ul" class="my-3 space-y-3 pl-3">
                                                        <li>
                                                            <NuxtLink to="/da-hood/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/da-hood.png" class="h-6 rounded-md" />
                                                                    <span>Da Hood</span>
                                                                    <VerifiedBadge class="h-[22px]" />
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/bladeball/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/bladeball.png" class="h-6 rounded-md" />
                                                                    <span>Blade Ball</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/mm2/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/mm2.svg" class="h-6 rounded-md" />
                                                                    <span>Murder Mystery 2</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/gpo/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/gpo.png" class="h-6 rounded-md" />
                                                                    <span>Grand Piece Online</span>
                                                                    <VerifiedBadge class="h-[22px]" />
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <li>
                                                            <NuxtLink to="/ps-99/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/ps99.png" class="h-6 rounded-md" />
                                                                    <span>Pet Simulator 99</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        <!--
                                                        <li>
                                                            <NuxtLink to="/adopt-me/ads" class="link">
                                                                <div class="flex items-center gap-x-2.5">
                                                                    <NuxtImg src="/images/games/icons/adopt-me.png" class="h-6 rounded-md" />
                                                                    <span>Adopt Me</span>
                                                                </div>
                                                            </NuxtLink>
                                                        </li>
                                                        -->
                                                    </HeadlessDisclosurePanel>
                                                </HeadlessDisclosure>
                                            </li>
                                            <li>
                                                <NuxtLink to="/staff" class="link">Staff</NuxtLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="mt-auto">
                                        <div class="flex items-center text-gray-900 dark:text-white font-medium text-sm">
                                            <LucideMoon class="mr-2 h-4 w-4" />
                                            <span>Dark Theme</span>
                                            <div class="ml-auto flex">
                                                <ShadSwitch :checked="$colorMode.preference === 'dark'" @click="$colorMode.preference = $colorMode.preference === 'dark' ? 'light' : 'dark'" class="h-5 w-10 darkSwitch" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </HeadlessDialogPanel>
                </HeadlessTransitionChild>
            </div>
        </HeadlessDialog>
    </HeadlessTransitionRoot>
    <header class="bg-white dark:bg-[#202226] fixed z-50 top-0 w-full">
        <nav id="mainNav" class="mx-auto flex items-center justify-between px-4 lg:px-8 h-20 lg:h-24">
            <div class="w-full flex items-center gap-x-10">
                <div class="flex flex-1">
                    <div class="flex lg:hidden -ml-2.5 mr-2">
                        <button type="button" class="p-2.5" @click="mobileMenuOpen = true">
                            <svg class="size-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex flex-shrink-0 items-center">
                        <NuxtLink to="/">
                            <NuxtImg class="h-5 w-auto mx-auto block dark:hidden" src="/logo-dark.png" />
                            <NuxtImg class="h-5 w-auto mx-auto hidden dark:block" src="/logo.png" />
                        </NuxtLink>
                    </div>
                    <HeadlessPopoverGroup class="hidden lg:ml-10 lg:flex lg:gap-x-8">
                        <NuxtLink to="/" class="text-sm link">Home</NuxtLink>
                        <HeadlessPopover @mouseenter="moreOpen = true" @mouseleave="moreOpen = false" class="relative">
                            <HeadlessPopoverButton @click="moreOpen = !moreOpen" class="group inline-flex items-center font-semibold leading-7 text-gray-900 dark:text-white hover:text-primary transition-colors duration-300 hover:text-primary gap-x-1.5 text-sm">
                                <span>Games</span>
                                <ChevronDownIcon :class="{ 'rotate-180 transform': moreOpen }" class="h-5 w-5 flex-none" />
                            </HeadlessPopoverButton>
                            <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                <HeadlessPopoverPanel v-if="moreOpen" class="absolute left-1/2 z-10 flex w-screen max-w-min -translate-x-1/2 px-4" static>
                                    <div class="w-60 space-y-2 shrink rounded-md bg-white dark:bg-[#202226] ring-1 ring-gray-900/5 dark:ring-white/5 py-3 px-4 mt-3.5">
                                        <NuxtLink to="/da-hood" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/da-hood.png" class="h-6 rounded-md" />
                                            <span>Da Hood</span>
                                            <VerifiedBadge class="h-[22px]" />
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/hood-modded" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/hood-modded.webp" class="h-6 rounded-md" />
                                            <span>Hood Modded</span>
                                        </NuxtLink>
                                        -->
                                        <NuxtLink to="/bladeball" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/bladeball.png" class="h-6 rounded-md" />
                                            <span>Blade Ball</span>
                                        </NuxtLink>
                                        <NuxtLink to="/mm2" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/mm2.svg" class="h-6 rounded-md" />
                                            <span>Murder Mystery 2</span>
                                        </NuxtLink>
                                        <NuxtLink to="/gpo" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/gpo.png" class="h-6 rounded-md" />
                                            <span>Grand Piece Online</span>
                                            <VerifiedBadge class="h-[22px]" />
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/psx" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/psx.webp" class="h-6 rounded-md" />
                                            <span>Pet Simulator X</span>
                                        </NuxtLink>
                                        -->
                                        <NuxtLink to="/ps-99" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/ps99.png" class="h-6 rounded-md" />
                                            <span>Pet Simulator 99</span>
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/adopt-me" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/adopt-me.png" class="h-6 rounded-md" />
                                            <span>Adopt Me</span>
                                        </NuxtLink>
                                        -->
                                        <!--
                                        <NuxtLink to="/anime-defenders" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/anime-defenders.webp" class="h-6 rounded-md" />
                                            <span>Anime Defenders</span>
                                        </NuxtLink>
                                        -->
                                    </div>
                                </HeadlessPopoverPanel>
                            </Transition>
                        </HeadlessPopover>
                        <HeadlessPopover @mouseenter="tradeAdsOpen = true" @mouseleave="tradeAdsOpen = false" class="relative">
                            <HeadlessPopoverButton @click="tradeAdsOpen = !tradeAdsOpen" class="group inline-flex items-center font-semibold leading-7 text-gray-900 dark:text-white hover:text-primary transition-colors duration-300 hover:text-primary gap-x-1.5 text-sm">
                                <span>Trade Ads</span>
                                <ChevronDownIcon :class="{ 'rotate-180 transform': tradeAdsOpen }" class="h-5 w-5 flex-none" />
                            </HeadlessPopoverButton>
                            <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                <HeadlessPopoverPanel v-if="tradeAdsOpen" class="absolute left-1/2 z-10 flex w-screen max-w-min -translate-x-1/2 px-4" static>
                                    <div class="w-60 space-y-2 shrink rounded-md bg-white dark:bg-[#202226] ring-1 ring-gray-900/5 dark:ring-white/5 py-3 px-4 mt-3.5">
                                        <NuxtLink to="/da-hood/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/da-hood.png" class="h-6 rounded-md" />
                                            <span>Da Hood</span>
                                            <VerifiedBadge class="h-[22px]" />
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/hood-modded/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/hood-modded.webp" class="h-6 rounded-md" />
                                            <span>Hood Modded</span>
                                        </NuxtLink>
                                        -->
                                        <NuxtLink to="/bladeball/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/bladeball.png" class="h-6 rounded-md" />
                                            <span>Blade Ball</span>
                                        </NuxtLink>
                                        <NuxtLink to="/mm2/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/mm2.svg" class="h-6 rounded-md" />
                                            <span>Murder Mystery 2</span>
                                        </NuxtLink>
                                        <NuxtLink to="/gpo/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/gpo.png" class="h-6 rounded-md" />
                                            <span>Grand Piece Online</span>
                                            <VerifiedBadge class="h-[22px]" />
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/psx/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/psx.webp" class="h-6 rounded-md" />
                                            <span>Pet Simulator X</span>
                                        </NuxtLink>
                                        -->
                                        <NuxtLink to="/ps-99/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/ps99.png" class="h-6 rounded-md" />
                                            <span>Pet Simulator 99</span>
                                        </NuxtLink>
                                        <!--
                                        <NuxtLink to="/adopt-me/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/adopt-me.png" class="h-6 rounded-md" />
                                            <span>Adopt Me</span>
                                        </NuxtLink>
                                        -->
                                        <!--
                                        <NuxtLink to="/anime-defenders/ads" class="link text-sm flex items-center gap-x-2.5">
                                            <NuxtImg src="/images/games/icons/anime-defenders.webp" class="h-6 rounded-md" />
                                            <span>Anime Defenders</span>
                                        </NuxtLink>
                                        -->
                                    </div>
                                </HeadlessPopoverPanel>
                            </Transition>
                        </HeadlessPopover>
                        <!--
                        <NuxtLink to="/blog" class="text-sm link">Blog</NuxtLink>
                        -->
                        <NuxtLink to="/staff" class="text-sm link">Staff</NuxtLink>
                        <!--
                        <NuxtLink to="/premium" class="text-sm font-semibold leading-6">Premium</NuxtLink>
                        <NuxtLink to="/advertise" class="text-sm font-semibold leading-6">Advertise</NuxtLink>
                        -->
                    </HeadlessPopoverGroup>
                </div>
                <!--
                <div class="flex flex-1 items-center justify-center px-2 lg:ml-10 lg:justify-end">
                    <div class="w-full max-w-lg lg:max-w-sm">
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input id="search" name="search" class="block w-full rounded-md border-0 bg-[#f5f5f5] py-2.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Search for items" type="search" />
                        </div>
                    </div>
                </div>
                -->
                <div class="flex items-center gap-x-2.5 justify-end">
                    <!--
                    <template v-if="colorMode.preference == 'light'">
                        <ShadButton class="p-3" variant="ghost" @click="colorMode.preference = 'dark'">
                            <svg viewBox="0 0 15 15" class="w-5 h-5 text-foreground">
                                <path fill="currentColor" fill-rule="evenodd" d="M2.9.5a.4.4 0 0 0-.8 0v.6h-.6a.4.4 0 1 0 0 .8h.6v.6a.4.4 0 1 0 .8 0v-.6h.6a.4.4 0 0 0 0-.8h-.6zm3 3a.4.4 0 1 0-.8 0v.6h-.6a.4.4 0 1 0 0 .8h.6v.6a.4.4 0 1 0 .8 0v-.6h.6a.4.4 0 0 0 0-.8h-.6zm-4 3a.4.4 0 1 0-.8 0v.6H.5a.4.4 0 1 0 0 .8h.6v.6a.4.4 0 0 0 .8 0v-.6h.6a.4.4 0 0 0 0-.8h-.6zM8.544.982l-.298-.04c-.213-.024-.34.224-.217.4q.211.305.389.632A6.602 6.602 0 0 1 2.96 11.69c-.215.012-.334.264-.184.417q.103.105.21.206l.072.066l.26.226l.188.148l.121.09l.187.131l.176.115q.18.115.37.217l.264.135l.26.12l.303.122l.244.086a6.6 6.6 0 0 0 1.103.26l.317.04l.267.02q.19.011.384.011a6.6 6.6 0 0 0 6.56-7.339l-.038-.277a6.6 6.6 0 0 0-.384-1.415l-.113-.268l-.077-.166l-.074-.148a6.6 6.6 0 0 0-.546-.883l-.153-.2l-.199-.24l-.163-.18l-.12-.124l-.16-.158l-.223-.2l-.32-.26l-.245-.177l-.292-.19l-.321-.186l-.328-.165l-.113-.052l-.24-.101l-.276-.104l-.252-.082l-.325-.09l-.265-.06zm1.86 4.318a7.6 7.6 0 0 0-.572-2.894a5.601 5.601 0 1 1-4.748 10.146a7.6 7.6 0 0 0 3.66-2.51a.749.749 0 0 0 1.355-.442a.75.75 0 0 0-.584-.732q.093-.174.178-.355A1.25 1.25 0 1 0 10.35 6.2q.052-.442.052-.9" clip-rule="evenodd"></path>
                            </svg>
                        </ShadButton>
                    </template>
<template v-else>
                        <ShadButton class="p-3" variant="ghost" @click="colorMode.preference = 'light'">
                            <svg viewBox="0 0 15 15" class="w-5 h-5 text-white">
                                <path fill="currentColor" fill-rule="evenodd" d="M7.5 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5M2.197 2.197a.5.5 0 0 1 .707 0L4.318 3.61a.5.5 0 0 1-.707.707L2.197 2.904a.5.5 0 0 1 0-.707M.5 7a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm1.697 5.803a.5.5 0 0 1 0-.707l1.414-1.414a.5.5 0 1 1 .707.707l-1.414 1.414a.5.5 0 0 1-.707 0M12.5 7a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm-1.818-2.682a.5.5 0 0 1 0-.707l1.414-1.414a.5.5 0 1 1 .707.707L11.39 4.318a.5.5 0 0 1-.707 0M8 12.5a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zm2.682-1.818a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 1 1-.707.707l-1.414-1.414a.5.5 0 0 1 0-.707M5.5 7.5a2 2 0 1 1 4 0a2 2 0 0 1-4 0m2-3a3 3 0 1 0 0 6a3 3 0 0 0 0-6" clip-rule="evenodd"></path>
                            </svg>
                        </ShadButton>
                    </template>
-->
                    <template v-if="!user">
                        <NuxtLink to="/auth/roblox" external class="connectRobloxAccount">
                            <div class="flex items-center gap-x-2.5">
                                <NuxtImg src="/images/roblox.png" class="h-5" />
                                <span>Log In</span>
                            </div>
                        </NuxtLink>
                    </template>
                    <NavbarProfile v-else />
                </div>
            </div>
        </nav>
    </header>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

const moreOpen = ref(false)
const tradeAdsOpen = ref(false)
const mobileMenuOpen = ref(false)

const user = useUser()

const nuxtApp = useNuxtApp()

nuxtApp.hook("page:finish", () => {
    moreOpen.value = false
    tradeAdsOpen.value = false
    mobileMenuOpen.value = false
})

/*useHead({
    bodyAttrs: computed(() => ({
        class: mobileMenuOpen.value ? 'overflow-hidden' : ''
    }))
})*/
</script>

<style scoped>
#sidebarNav .link {
    @apply text-gray-900 dark:text-white hover:text-primary block text-sm/6 font-semibold;
}

#mainNav .link {
    @apply font-semibold leading-7 text-gray-900 dark:text-white hover:text-primary transition-colors duration-300 hover:text-primary;
}

#sidebarNav .link.router-link-active,
#mainNav .link.router-link-active {
    @apply text-primary;
}

.connectRobloxAccount {
    @apply bg-[#cb0b0e] hover:bg-[#b50609] hover:text-white py-2.5 px-4 rounded-md text-sm font-semibold leading-6 text-white;
}
</style>