<template>
    <footer class="bg-[#1A1D2B] dark:bg-[#202226]">
        <div class="container mx-auto p-0 md:p-8 xl:px-0">
            <div class="mx-auto max-w-7xl px-6 pb-6 pt-8 lg:pt-12">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div class="space-y-6">
                        <div>
                            <NuxtLink to="/">
                                <div class="flex items-center space-x-2 text-2xl font-medium">
                                    <span>
                                        <NuxtImg src="/logo.png" class="h-5" />
                                    </span>
                                </div>
                            </NuxtLink>
                        </div>
                        <div class="max-w-md pr-16 text-md text-gray-300 font-medium text-sm">A multi-community driven source of reliable values, data, updates, leaks, trading information and more!</div>
                        <div class="flex gap-x-3.5">
                            <NuxtLink :to="`https://discord.gg/${config.discord}`" class="social discord" target="_blank" v-if="config.discord">
                                <i class="fa-brands fa-discord"></i>
                            </NuxtLink>
                            <NuxtLink :to="`https://instagram.com/${config.instagram}`" class="social instagram" target="_blank" v-if="config.instagram">
                                <i class="fa-brands fa-instagram"></i>
                            </NuxtLink>
                            <NuxtLink :to="`https://x.com/${config.twitter}`" class="social twitter" target="_blank" v-if="config.twitter">
                                <i class="fa-brands fa-x-twitter"></i>
                            </NuxtLink>
                            <NuxtLink :to="`https://tiktok.com/@${config.tiktok}`" class="social tiktok" target="_blank" v-if="config.tiktok">
                                <i class="fa-brands fa-tiktok"></i>
                            </NuxtLink>
                            <NuxtLink :to="`https://youtube.com/${config.youtube}`" class="social youtube" target="_blank" v-if="config.youtube">
                                <i class="fa-brands fa-youtube"></i>
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="mt-10 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-md font-semibold leading-6 text-white">About</h3>
                                <ul role="list" class="mt-4 space-y-4">
                                    <li>
                                        <NuxtLink to="/faq" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            FAQ
                                        </NuxtLink>
                                    </li>
                                    <!--
                                    <li>
                                        <NuxtLink to="/aialgorithms" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Safe Trading Guide
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/industryapplications" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Contact Us
                                        </NuxtLink>
                                    </li>
                                    -->
                                </ul>
                            </div>
                            <div class="mt-10 md:mt-0">
                                <h3 class="text-md font-semibold leading-6 text-white">Games</h3>
                                <ul role="list" class="mt-4 space-y-2.5">
                                    <li>
                                        <NuxtLink to="/da-hood" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Da Hood
                                        </NuxtLink>
                                    </li>
                                    <!--
                                    <li>
                                        <NuxtLink to="/hood-modded" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Hood Modded
                                        </NuxtLink>
                                    </li>
                                    -->
                                    <li>
                                        <NuxtLink to="/bladeball" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Blade Ball
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/mm2" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Murder Mystery 2
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/gpo" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Grand Piece Online
                                        </NuxtLink>
                                    </li>
                                    <!--
                                    <li>
                                        <NuxtLink to="/psx" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Pet Simulator X
                                        </NuxtLink>
                                    </li>
                                    -->
                                    <li>
                                        <NuxtLink to="/ps-99" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Pet Simulator 99
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/adopt-me" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Adopt Me
                                        </NuxtLink>
                                    </li>
                                    <!--
                                    <li>
                                        <NuxtLink to="/anime-defenders" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Anime Defenders
                                        </NuxtLink>
                                    </li>
                                    -->
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 class="text-md font-semibold leading-6 text-white">Resources</h3>
                                <ul role="list" class="mt-4 space-y-4">
                                    <!--
                                    <li>
                                        <NuxtLink to="/blog" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Blog
                                        </NuxtLink>
                                    </li>
                                    -->
                                    <li>
                                        <NuxtLink to="/staff" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Staff
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-10 md:mt-0">
                                <h3 class="text-md font-semibold leading-6 text-white">Legal</h3>
                                <ul role="list" class="mt-4 space-y-2.5">
                                    <li>
                                        <NuxtLink to="/terms" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Terms of Service
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/privacy-policy" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Privacy Policy
                                        </NuxtLink>
                                    </li>
                                    <li>
                                        <NuxtLink to="/community-guidelines" class="font-medium leading-6 text-sm text-gray-300 hover:text-gray-50">
                                            Community Guidelines
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-12 sm:mt-20 lg:mt-12">
                    <div class="text-sm font-medium text-center text-gray-300">
                        Copyright © 2024 RoValue
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
const config = useState<Config>('config')
</script>

<style scoped>
.social {
    @apply text-gray-200 transition-all duration-300;
}

.twitter:hover {
    @apply text-[#1DA1F2];
}

.youtube:hover {
    @apply text-[#FF0000];
}

.tiktok:hover {
    @apply hover:text-[#ee1d52];
}

.discord:hover {
    @apply text-[#7289da];
}

.instagram:hover {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
}
</style>