<template>
    <ShadDropdownMenu :modal="false" @update:open="open = !open">
        <ShadDropdownMenuTrigger>
            <button class="flex items-center justify-center whitespace-nowrap text-sm dark:text-white font-medium gap-x-2.5">
                <NuxtImg :src="user?.avatar" class="h-7 mr-0.5" />
                <div class="hidden lg:block flex flex-1 flex-col text-left">
                    <p>{{ user?.roblox_name }}</p>
                </div>
                <ChevronDownIcon :class="{ 'rotate-180 transform': open }" class="h-5 transition duration-300 group-hover:text-gray-300 hidden lg:block" />
            </button>
        </ShadDropdownMenuTrigger>
        <ShadDropdownMenuContent class="mt-2.5 w-56" align="end">
            <ShadDropdownMenuGroup class="hidden lg:block">
                <ShadDropdownMenuItem class="cursor-default focus:bg-transparent dark:focus:bg-transparent" @select="(event) => event.preventDefault()">
                    <LucideMoon class="mr-2 h-4 w-4" />
                    <span>Dark Theme</span>
                    <div class="ml-auto flex">
                        <ShadSwitch :checked="$colorMode.preference === 'dark'" @click="$colorMode.preference = $colorMode.preference === 'dark' ? 'light' : 'dark'" class="h-5 w-10 darkSwitch" />
                    </div>
                </ShadDropdownMenuItem>
            </ShadDropdownMenuGroup>
            <ShadDropdownMenuSeparator class="hidden lg:block" />
            <ShadDropdownMenuItem @click="useAuthStore().logout()">
                Log Out
            </ShadDropdownMenuItem>
        </ShadDropdownMenuContent>
    </ShadDropdownMenu>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const user = useUser()
const open = ref(false)
</script>

<style>
.darkSwitch>span {
    @apply !w-4 !h-4;
}
</style>